<template>
  <div class="container-top bg">
    <!-- pc -->
    <div class="pc findpw-container">
      <div class="subtitle3 main" style="margin-bottom: 40px">비밀번호 재설정</div>
      <div class="findpw-label">{{ form.label }}</div>
      <lp-text v-bind.sync="form"
               class="margin-top-8" @onEnter="passwordReset"></lp-text>

      <div class="body4 sub3" style="text-align: left;margin-top:16px">회원가입 시 작성한 이메일을 통해 새 비밀번호로 변경할 수 있는 링크가 전송됩니다.</div>
      <button class="button is-primary" style="width:100%;margin-top:40px" @click="passwordReset">완료</button>
    </div>

    <!-- mobile -->
    <div class="mobile findpw-container">
      <div class="h7 main" style="margin-bottom: 32px">비밀번호 재설정</div>
      <div class="findpw-label">{{ form.label }}</div>
      <lp-text v-bind.sync="form"
               class="margin-top-8" @onEnter="passwordReset"></lp-text>

      <div class="body5 sub3" style="text-align: left;margin-top:8px">회원가입 시 작성한 이메일을 통해 새 비밀번호로 변경할 수 있는 링크가 전송됩니다.</div>
      <button class="button is-primary body2-medium" style="width:100%;margin-top:40px;height:48px" @click="passwordReset">완료</button>
    </div>
  </div>
</template>

<script>
  import LpText from "../component/LpText";
  import FormMixin from "../../mixins/FormMixin";

  export default {
    name: "FindPw",
    mixins:[
      FormMixin
    ],
    components: {LpText},
    data() {
      return {
        form:{
          label: '이메일',
          placeholder: 'example@gmail.com',
          maxLength: 100,
          field: 'email',
          type: 'email',
          value: '',
          component_name: 'lp-text',
          errorMsg: '',
          validate: {
            valid_required: true,
            required: true
          }
        },
      }
    },
    methods: {
      passwordReset() {
        let result = this.getParamData([this.form]);
        if(result.enable) {
          this.$store.commit('setLoading', true);
          this.$axios.post('auth/user/reset/password', result.params).then(res => {
            if (res.status === 200) {
              this.$store.commit('setLoading', false);
              this.setGa('새 비밀번호 전송 완료', '응답', '새 비밀번호 전송 완료');
              this.toast('비밀번호 재 설정이 완료되었습니다. 이메일에서 확인 해 주세요.');
              this.routerPush('/signin')
            }
          }).catch(error => {
            this.$store.commit('setLoading', false);
            if (error.response) {
              let res = error.response;
              if (res.status !== 200) {
                this.toast(res.data.detail);
              }
            }
          });
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .bg
    background-color $gray4
    display flex
    justify-content center
    align-items flex-start
  .findpw-container
    border 1px solid $gray2
    border-radius 12px
    background-color white
    padding 40px
    margin-top 80px
    width 448px
    text-align center
  .findpw-label
    text-align left
    margin-top 16px
    color $sub3
    font-size 15px


  @media (max-width:1024px)
    .findpw-container
      margin 40px 16px
      padding 32px 20px
    .findpw-label
      font-size 14px
</style>
